<template>
  <div id="tenant-detail">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ name: 'tenant' }">租户管理</el-breadcrumb-item>
      <el-breadcrumb-item>租户详情</el-breadcrumb-item>
    </el-breadcrumb>
    <br />
    <div class="cm-content">
      <div class="card-title">应用设置</div>
      <br />
      <el-table :data="applicationData" border>
        <el-table-column
          label="套件"
          prop="app.name"
          width="100"
        ></el-table-column>
        <el-table-column label="程序" prop="sub.label"></el-table-column>
        <el-table-column label="授权范围" prop="role_scope">
          <template slot-scope="scope">
            {{ scope.row.role_scope | formatTenantApplicationRoleScope }}
          </template>
        </el-table-column>
        <el-table-column
          label="入口地址"
          prop="entry_url"
          show-overflow-tooltip
        ></el-table-column>
        <el-table-column>
          <template slot-scope="scope">
            <el-button size="mini" @click="handleEditApplication(scope.row.pk)"
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <br />
      <el-row :gutter="60">
        <el-col :span="14">
          <div class="card-title">用户列表</div>
          <br />
          <div id="h-tenant" class="h-search">
            <el-input
              class="mb10 fl wid-25 mr10"
              size="small"
              clearable
              v-model="userParams.keyword"
              placeholder="用户昵称/手机号码模糊查询"
            ></el-input>
            <el-select
              class="mb10 fl wid-10 mr10"
              size="small"
              clearable
              v-model="userParams.role"
              placeholder="选择角色"
            >
              <el-option label="成员" :value="3"></el-option>
              <el-option label="代理" :value="2"></el-option>
              <el-option label="客户" :value="1"></el-option>
            </el-select>
            <el-button
              class="mb10 fl wid-5"
              size="small"
              type="primary"
              @click="handleUserSearch"
            >
              查询
            </el-button>
          </div>
          <br />
          <el-table :data="userData" border>
            <el-table-column
              label="用户昵称"
              prop="user_nickname"
            ></el-table-column>
            <el-table-column
              label="用户手机"
              prop="user_cellphone"
            ></el-table-column>
            <el-table-column label="是否为管理员" prop="is_super">
              <template slot-scope="scope">
                {{ scope.row.is_super | formatIsSuper }}
              </template>
            </el-table-column>
            <el-table-column label="角色" prop="role">
              <template slot-scope="scope">
                {{ scope.row.role | formatTenantUserRole }}
              </template>
            </el-table-column>
            <el-table-column label="加入时间" prop="created_at">
              <template slot-scope="scope">
                {{ scope.row.created_at | formatDateTime }}
              </template>
            </el-table-column>
            <el-table-column label="邀请码" prop="invite_code">
              <template slot-scope="scope">
                {{ scope.row.invite_code | formatNullValue }}
              </template>
            </el-table-column>
            <el-table-column label="是否加邀请人好友" prop="is_add_inviter">
              <template slot-scope="scope">
                {{ scope.row.is_add_inviter | formatIsAddInviter }}
              </template>
            </el-table-column>
            <el-table-column label="操作" width="240px">
              <template slot-scope="scope">
                <el-dropdown
                  size="small"
                  split-button
                  type="primary"
                  trigger="click"
                  @command="handleChangeUserRole"
                >
                  修改角色
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item :command="{ pk: scope.row.pk, role: 1 }"
                      >设为客户</el-dropdown-item
                    >
                    <el-dropdown-item :command="{ pk: scope.row.pk, role: 2 }"
                      >设为代理</el-dropdown-item
                    >
                    <el-dropdown-item :command="{ pk: scope.row.pk, role: 3 }"
                      >设为成员</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
                <el-button
                  style="margin-left: 10px"
                  size="small"
                  @click="handleSetInviteCode(scope.row.pk, scope.row.role)"
                  :disabled="
                    scope.row.role === 1 || scope.row.invite_code !== ''
                  "
                  >设置邀请码</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <br />
          <el-row type="flex" justify="center">
            <el-pagination
              background
              layout="prev, pager, next"
              @current-change="handleUserCurrentChange"
              :current-page="userCurrentPage"
              :page-size="10"
              :total="userTotal"
            >
            </el-pagination>
          </el-row>
        </el-col>
        <el-col :span="10">
          <div class="card-title">租户信息</div>
          <br />
          <el-form
            :model="form"
            ref="form"
            label-width="68px"
            label-position="left"
          >
            <el-row :gutter="60">
              <el-col :span="12">
                <el-form-item label="租户图片">
                  <c-image-upload
                    :image="form.icon_url"
                    @change="handleImageChange"
                  ></c-image-upload>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="租户名称" prop="name">
                  <el-input
                    size="small"
                    type="text"
                    v-model="form.name"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-button size="small" type="primary" @click="handleOk('form')"
              >保存</el-button
            >
          </el-form>
        </el-col>
      </el-row>
      <d-tenant-application
        ref="dialogApplication"
        @change="handleDialogChange"
      ></d-tenant-application>
      <d-tenant-user-invite-code
        ref="dialogTenantUserInviteCode"
        @change="handleUserInviteCodeDialogChange"
      ></d-tenant-user-invite-code>
    </div>
  </div>
</template>

<script>
import tenant from "@/api/tenant";

import CImageUpload from "@/components/CImageUpload";
import DTenantApplication from "@/components/dialogs/DTenantApplication";
import DTenantUserInviteCode from "@/components/dialogs/DTenantUserInviteCode";

export default {
  name: "tenant-detail",
  data() {
    return {
      form: {
        name: "",
        icon_url: "",
      },
      userData: [],
      userTotal: 0,
      userParams: {
        page: 1,
        keyword: "",
      },
      userCurrentPage: 1,
      userKeyword: "",
      applicationData: [],
    };
  },
  created() {
    this.handleInit();
  },
  components: {
    CImageUpload,
    DTenantApplication,
    DTenantUserInviteCode,
  },
  methods: {
    handleInit() {
      tenant.get(this.$route.params.pk).then((res) => {
        this.form = res.results;
      });

      this.handleListUser();
      this.handleListApplication();
    },
    handleImageChange(val) {
      this.form.icon_url = val;
    },
    handleOk(formName) {
      this.$confirm("确认保存？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$refs[formName].validate((valid) => {
            if (valid) {
              tenant
                .update(this.form.pk, this.form)
                .then(() => {
                  this.visible = false;
                  this.$message({
                    type: "success",
                    message: "操作成功！",
                  });
                  this.$emit("change", true);
                })
                .catch((err) => {
                  this.$message({
                    type: "warning",
                    message: err.message,
                  });
                });
            }
          });
        })
        .catch(() => {});
    },
    handleListUser(params) {
      tenant.listUser(this.$route.params.pk, params).then((res) => {
        this.userData = res.results;
        this.userTotal = res.count;
      });
    },
    handleUserCurrentChange(val) {
      this.userCurrentPage = val;
      this.userParams.page = val;
      this.handleListUser(this.userParams);
    },
    handleUserSearch() {
      this.userCurrentPage = 1;
      this.userParams.page = 1;
      this.handleListUser(this.userParams);
    },
    handleChangeUserRole(val) {
      let data = {
        role: val.role,
      };
      tenant
        .changeUserRole(val.pk, data)
        .then((res) => {
          this.$message({
            type: "success",
            message: "设置成功！",
          });
          this.handleListUser(this.userParams);
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    handleSetInviteCode(pk, role) {
      this.$refs.dialogTenantUserInviteCode.handleShow(pk, role);
    },
    handleListApplication() {
      tenant.listApplication(this.$route.params.pk).then((res) => {
        this.applicationData = res.results;
      });
    },
    handleEditApplication(pk) {
      this.$refs.dialogApplication.handleEdit(pk);
    },
    handleDialogChange() {
      this.handleListApplication();
    },
    handleUserInviteCodeDialogChange() {
      this.handleListUser();
    },
  },
};
</script>

<style lang="stylus">
#tenant-detail {
  display: flex;
  height: 100%;
  flex-direction: column;

  .label {
    color: #00000073;
  }
  .current {
    height: 44px;
    color: #000000d9;
    font-size: 30px;
    font-weight: bold;
  }

  .sub {
    span {
      margin-right: 20px;
    }
  }

  .card-title {
    color: rgba(0,0,0,.85);
    font-weight: 500;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 32px;
  }
}
</style>
