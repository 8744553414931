<template>
  <div id="d-tenant-user-invite-code">
    <el-dialog
      title="设置邀请码"
      :visible.sync="visible"
      @closed="handleClosed"
      width="400px"
    >
      <el-form :model="form" ref="form" label-width="80px" label-position="top">
        <el-form-item :label="label">
          <el-input
            size="small"
            type="text"
            v-model="form.code"
            placeholder="邀请码"
            ><el-button slot="append" @click="handleCheckTenantUserInviteCode"
              >检测</el-button
            ></el-input
          >
        </el-form-item>
        <el-form-item label="是否添加邀请人为好友">
          <el-switch
            v-model="form.is_add_inviter"
            active-color="#13ce66"
            inactive-color="#ff4949"
            :active-value="1"
            :inactive-value="-1"
          ></el-switch>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleCancel">取 消</el-button>
        <el-button size="small" type="primary" @click="handleOk('form')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tenant from "@/api/tenant";

export default {
  name: "d-tenant-user-invite-code",
  data() {
    return {
      visible: false,
      pk: null,
      role: null,
      label: "",
      form: {
        code: "",
        is_add_inviter: 1,
      },
    };
  },
  methods: {
    handleShow(pk, role) {
      this.pk = pk;
      let length = 0;
      if (role === 2) {
        this.label = "5位邀请码";
        length = 5;
      } else {
        this.label = "4位邀请码";
        length = 4;
      }
      this.visible = true;
      this.form.code = this.handleRandomCode(length);
    },
    handleRandomCode(length) {
      let strHead = "ABCDEFGHIJKLMNPQRSTUVWXYZ";
      let strBack = "0123456789";

      var result = "";
      for (var i = length - 1; i > 0; --i)
        result += strBack[Math.floor(Math.random() * strBack.length)];

      return strHead[Math.floor(Math.random() * strHead.length)] + result;
    },
    handleOk(formName) {
      this.$refs[formName].validate((valid) => {
        if (this.role === 3 && this.form.code.length !== 4) {
          this.$message({
            type: "warning",
            message: "成员角色邀请码需要4位",
          });
          return;
        }

        if (this.role === 2 && this.form.code.length !== 4) {
          this.$message({
            type: "warning",
            message: "代理角色邀请码需要5位",
          });
          return;
        }

        let data = {
          code: this.form.code.toUpperCase(),
          is_add_inviter: this.form.is_add_inviter,
        };
        tenant
          .setUserInviteCode(this.pk, data)
          .then(() => {
            this.visible = false;
            this.$message({
              type: "success",
              message: "设置成功！",
            });
            this.$emit("change", true);
          })
          .catch((err) => {
            this.$message({
              type: "warning",
              message: err.message,
            });
          });
      });
    },
    handleCheckTenantUserInviteCode() {
      let data = {
        code: this.form.code.toUpperCase(),
      };
      tenant
        .checkUserInviteCode(data)
        .then(() => {
          this.$message({
            type: "success",
            message: "可以使用！",
          });
        })
        .catch((err) => {
          this.$message({
            type: "warning",
            message: err.message,
          });
        });
    },
    handleCancel() {
      this.visible = false;
    },
    handleClosed() {
      this.form = {
        code: "",
        is_add_inviter: 1,
      };
    },
  },
};
</script>
