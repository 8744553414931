<template>
  <div id="d-tenant-application">
    <el-dialog
      :title="title"
      :visible.sync="visible"
      @closed="handleClosed"
      width="600px"
    >
      <el-form
        :model="form"
        ref="form"
        label-width="80px"
        label-position="left"
      >
        <el-form-item label="应用名称">
          <el-select style="width: 100%" size="small" v-model="form.sub_id">
            <el-option
              label="进店"
              value="f07d4d0376cfd3ec117b6e0b7b51b9f5"
            ></el-option>
            <el-option
              label="后台"
              value="79fb3d7802c3a88cc2f9dc7d6eb478bb"
            ></el-option>
            <el-option
              label="比分"
              value="bc96a3b63b169e007545f9937e5daf78"
            ></el-option>
            <el-option
              label="代理"
              value="c8ffedc9217a29e9c5a605b3ae1fd14f"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="应用入口">
          <el-input
            size="small"
            type="text"
            v-model="form.entry_url"
            placeholder="应用入口"
          ></el-input>
        </el-form-item>
        <el-form-item label="可见范围">
          <el-radio-group size="small" v-model="form.role_scope">
            <el-radio :label="0">全员可见</el-radio>
            <el-radio :label="1">客户可见</el-radio>
            <el-radio :label="2">代理可见</el-radio>
            <el-radio :label="3">成员可见</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="handleCancel">取 消</el-button>
        <el-button size="small" type="primary" @click="handleOk('form')"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import tenant from "@/api/tenant";

export default {
  name: "d-tenant-application",
  data() {
    return {
      visible: false,
      pk: null,
      form: {
        pk: null,
        app_id: "mq05722d531dbb",
        sub_id: "",
        role_scope: 0,
        entry_url: "",
      },
      title: "添加应用",
      action: "create",
    };
  },
  methods: {
    handleAdd(val) {
      this.visible = true;
      this.action = "create";
      this.title = "添加应用";
      this.pk = val;
    },
    handleEdit(pk) {
      this.action = "edit";
      this.title = "编辑应用";
      tenant.getApplication(pk).then((res) => {
        this.visible = true;
        this.form = res.results;
      });
    },
    handleOk(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.action === "create") {
            tenant
              .createApplication(this.pk, this.form)
              .then(() => {
                this.visible = false;
                this.$message({
                  type: "success",
                  message: "添加成功！",
                });
              })
              .catch((err) => {
                this.$message({
                  type: "warning",
                  message: err.message,
                });
              });
          } else {
            tenant
              .changeApplication(this.form.pk, this.form)
              .then(() => {
                this.visible = false;
                this.$message({
                  type: "success",
                  message: "编辑成功！",
                });
                this.$emit("change", false);
              })
              .catch((err) => {
                this.$message({
                  type: "warning",
                  message: err.message,
                });
              });
          }
        }
      });
    },
    handleCancel() {
      this.visible = false;
    },
    handleClosed() {
      this.form = {
        app_id: "mq05722d531dbb",
        sub_id: "",
        role_scope: 0,
        entry_url: "",
      };
      this.$refs.form.resetFields();
    },
  },
};
</script>
